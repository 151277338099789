.content-main {
  background-color: black;
  overflow: hidden;
}

.mainsites-header-container {
  position: absolute;
  display: flex;
}

.mainsites-header-image {
  width: 100%;
}

.mainsites-header-text.factory {
  top: 30%;
  left: 50%;
}

.mainsites-header-text.atelier {
  top: 20%;
  left: 50%;
}


.mainsites-header-text.trend {
  top: 30%;
  left: 70%;
}


.parallax-header {
  position: relative;
  height: 80vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parallax-content {
  position: relative;
  margin-top: 20vh;
}

.parallax-image {
  width: 100vw;
  min-height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.mainsiteswrapper {
  position: relative;
  margin-top: -30em;
}


.mainsites-header-text {
  position: absolute;
  top: 25%;
  left: 35%;
  color: #F7EAD2;
  font-size: 1.3em;
  text-align: center;
  font-family: 'Times New Roman';
  font-weight: normal;
}

.mainsites-header-text h1{
  font-weight: normal;
}

.mainsites-content-section {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  margin-right: 5%;
  width: 95%;
  background-color: #ffffff;
  z-index: 1;
  padding-top: 1%;
  padding-bottom: 10vh;
}

.content-section {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 20%;
  padding-top: 5vh;
  padding-bottom: 5vh;
  overflow: hidden;
  margin-right: 10%;
  z-index: 1;
  text-align: start;

}

.text-block.reverse{
  margin-left: 5vw;
}

.content-section.overflow{
  overflow: visible;
  justify-content: end;
  align-items: flex-start;
}


.content-section h1 {
  font-size: 2em;
  margin: 0;
  font-family: 'Times New Roman';
}
.content-section a {
  text-decoration: none;
  color: black;
  align-self: flex-start;
}

.content-section.vertical-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.content-video {
  max-width: 100%;
  height: auito;
  display: block;
}

.yellowBackground {
  background-color: #F3F1EC;
  position: relative;
  z-index: 0;
  margin-top: 10vh;
  margin-bottom: 10vh;
}


.yellowBackground.atelier {
  margin-top: 10vh;
}


.yellowBackground.factory {
  margin-top: 5%;
}



.text-block {
  flex: 1;
  padding: 1em;
  padding-left: 0;
  font-family: 'Times New Roman', serif;
  font-weight: normal;
}

.text-block h2 {
  font-size: 2em;
  margin-bottom: 0.5em;
  font-weight: normal;
  margin-top: 1em;
}

.text-block p {
  font-size: 1.3em;
}

h1 {
  font-weight: normal;
}

.text-block.factory {
  margin-left: -30%;
}

.text-block-trend-header {
  margin-top: 5%;
  font-family: 'Times New Roman';
}

.text-block-trend-header h1{
  font-weight: normal;
  font-size: 1.5em;
}

.text-block-trend-header p{
  font-size: 1.2em;
}

.title-images-trend {
  align-self: flex-start;
  text-decoration: none;
  color: black;
}


.image-block {
  flex: 1;
  width: 100%;
}

.image-block img {
  max-width: 100%;
}

.image-block.vertical img {
  height: auto;
  width: 100%;
}

.image-block.small{
  display: flex;
flex-direction: row-reverse;
}

.image-block.small img {
  width: 20vw;
}


.image-block.smaller img {
  width: 30vw;
}


.image-block.overflow {
  display: flex;          /* Use flex to control the alignment */
  justify-content: flex-end; /* Aligns the content to the right */
  align-items: flex-end;   /* Aligns the content vertically to the bottom */
  position: relative;      /* To handle overflow properly */
  overflow: visible;
}

.overflow-image {
  height: 100%;
  margin-left: auto;        /* Pushes the image to the right */
  align-self: flex-end;     /* Vertically aligns the image to the bottom */
  margin-top: -20%;        /* Push the image up outside the yellow box */
}



.image-block.trend {
  width: 100%;

  /* Set the height to 100% of its container */
  display: flex;
  /* Add flex to allow alignment */
  justify-content: center;
  align-items: center;
}

.image-block.trend img {
  width: 100%;
  height: 100%;
  /* Make the image fill the container */
  object-fit: cover;
  /* Scale the image to cover the container */
}

.trend-image-container {
  display: flex;
  margin-left: 4.5em;
  align-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.image-subtitles-trend {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
}

.text-line{
  display: flex;
  flex-direction: row;

}

.text-line p{
  padding-bottom: 5%;
  font-family: Raleway;
  color: #A79688;
  width: 5em;

}

.line-container{
  width: 6em;
  display: flex;
  align-items: center;
  margin-left: 2%;
}

.line{
  width: 100%; /* Full-width line */
  height: 1px; /* Line thickness */
  background-color: #A79688;
}




.content-section-jewelry {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-left: 20%;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  overflow: visible;
  padding-right: 10%;
  z-index: 1;
  font-family: 'Times New Roman';
}

.jewelry-number {
  flex: 3;
  font-size: 1.8em;
}


.jewelry-content {
  flex: 7;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.jewelry-content h2 {
  color: #A79688;
}

.jewelry-link {
  display: inline-block;
  /* Make the text box-shaped */
  padding: 0.5em 1.5em;
  /* Add padding around the text */
  border: 1px solid #A79688;
  /* Yellow border around the box */
  /* Light yellow background */
  color: #A79688;

  font-size: 1em;
  text-align: center;
  margin-top: 0.8em;

  cursor: pointer;

}


.jewelry-link a {
  text-decoration: none;
  color: rgb(203, 203, 185);
  ;
  /* Text color */
}

.text-block-trend-header p{
  margin-left: 7rem;
  }


  #fullimg{
    width: 100%;
  }

  .trend-image-container a{
    width: 100%;
  }


@media (max-width: 900px) {

  .content-main {
    overflow-x: hidden;
    margin-bottom: 15vh;
  }



  .mainsites-header-text {
    top: 15vh !important;
    left: 0% !important;
    text-align: center;
    width: 100%;
  }


  .content-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 5%;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    padding-bottom: 5vh;
  }

  
  .content-section.overflow {
margin-top: 0;
  }

  #vertical1 .text-block{
order: 2;
  }


  .content-section .text-block {
    order: 1;
  }

  .content-section .image-block {
    order: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content-video{
    order: 2;
  }

  .custom-picture{
    order: 2;
  }


.text-block{
  max-width: 100%;
}

.text-block h2{
text-align: center;
font-size: 2rem;
margin-top: 5vh;
}

.text-block p{
  text-align: center;
  max-width: 100%;
  font-size: 1.5rem;
}

.text-block p + p{
  margin-top: 0;
}


.parallax-image{
  height: 100vh;
}

.content-section h1{
  margin: 5%;
}

.content-section a{
  align-self: center;
  margin-bottom: 5%;
}

.image-block{
  max-width: 100%;
}

.image-block.overflow{
  overflow: auto;
  align-items: center;
  justify-content: center;
}

.overflow-image {
  height: auto;
  margin: 0;
}


.image-block.small img{
  max-width: 100%;
  width: auto;
}

.image-block.smaller img{
  max-width: 100%;
  width: auto;
}

.mainsites-content-section{
  margin-top: 40vh;
  margin-right: 0;
  width: 100%;
  padding-bottom: 0;
}


.jewelry-number{
  display: none;
}

.text-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 0;
  margin-bottom: 5%;
}

.text-block.reverse {
  margin: 0;
}

.text-block-trend-header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
}

.text-block-trend-header p{
margin: 0;
font-size: 1.5rem;
}

.text-block-trend-header h1{
  margin-bottom: 1em;
  font-size: 2rem;
  }

  .trend-image-container{
    margin: 0;
  }


  .image-subtitles-trend{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 5%;
  }

  .image-subtitles-trend p{
    margin: 0;
  }


  .content-section-jewelry {
    margin: 5%;
    padding: 0;
  }

  .yellowBackground{

    padding-bottom: 5vh;
    margin-bottom: 0;
    margin-top: 0;
  }

  .content-section + .yellowBackground{
    margin-top: 5vh;
  }


  
  .yellowBackground.factory{


    padding-bottom: 5vh;
  }

  .jewelry-content {
margin-bottom: 10vh;
  }

  .jewelry-content h2 {
    margin-top: 0;
  }

  .trend .content-section + .content-section{
    margin-bottom: 10vh;
  }

  .yellowBackground.atelier {
    margin-top: 0;
  }



}
@media only screen and (max-height: 500px) {

  .parallax-content{
    margin-top: 100vh;
    margin-bottom: 10vh;
  }


}


@media only screen and (max-width: 500px) {

  .text-block p{
    font-size: 1rem;
  }


  .text-block-trend-header p {
    font-size: 1rem;
}


.image-block.small img{
  width: 100%;
  max-width: 100%;
}

.image-block.smaller img{
  width: 100%;
  max-width: 100%;
}

.overflow-image{

  width: 100%;
}

}


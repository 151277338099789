/* src/subsites/DownwardArrow.css */

.arrow-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers child elements horizontally */
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%); /* Centers the container horizontally */
  z-index: 1;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

.dots_arrow {
  position: relative;
  width: 10px; /* Adjust width as needed */
  height: 5em; /* Adjust height as needed */
  overflow: hidden; /* Hides dots after they move beyond the container */
}

.dot_arrow {
  position: absolute;
  top: 0; /* Starts dots at the top */
  left: 50%; /* Positions dots at 50% of container width */
  transform: translateX(-50%); /* Centers dots horizontally */
  width: 2px;
  height: 2px;
  background-color: white;
  border-radius: 50%;
  opacity: 0;
  animation: moveDotDown 2.5s linear infinite;
}

.dots_arrow .dot_arrow:nth-child(1) {
  animation-delay: 0s;
}

.dots_arrow .dot_arrow:nth-child(2) {
  animation-delay: 0.5s;
}

.dots_arrow .dot_arrow:nth-child(3) {
  animation-delay: 1s;
}

.dots_arrow .dot_arrow:nth-child(4) {
  animation-delay: 1.5s;
}

.dots_arrow .dot_arrow:nth-child(5) {
  animation-delay: 2s;
}

@keyframes moveDotDown {
  0% {
    top: 0;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    top: 100%;
    opacity: 0;
  }
}

/* Style for the arrow composed of two lines */
.arrow {
  position: relative;
  width: 1em; /* Adjust width as needed */
  height: 0.5em; /* Adjust height as needed */
}

.arrow .line {
  position: absolute;
  width: 2px; /* Thickness of the lines */
  height: 100%;
  background-color: white;
}

.arrow .left {
  left: 50%;
  transform: translateX(-50%) rotate(315deg);
  transform-origin: bottom center;
}

.arrow .right {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  transform-origin: bottom center;
}

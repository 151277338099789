.product-catalog {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 2em; 
    opacity: 1;
    position: relative;
    top: 0;
    left: 0;
    pointer-events: all;
    margin-right: 5%;
    transition: opacity ease-in-out 0.3s;
  }
  
.product-catalog.hidden{
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
}

@media only screen and (max-width: 1000px) {

  .product-catalog{
    gap: 1em;
    grid-template-columns: repeat(2, 1fr);
  }


}


/* src/subsites/Jewelry.css */
.jewelry {
    display: flex;
    flex-direction: row;
    background-color: #F5F5F5;
  }
  
  .jewelry-container {
    margin-top: 12vh;
    display: flex;
    width: 95%;
    background-color: white;
    position: relative;
    padding-bottom: 5%;

  }
  
  .jewelry-content-container{
    display: flex;
    margin-top: 15vh;
    width: 100%;
    position: relative;
    margin-bottom: 10vh;
    margin-left: 20%;
  }

  .jewelry-header-text{
    position: absolute;
    align-self: flex-start;
    left: 0;
    top: -2em;
    font-family: 'Times New Roman'; 
    font-size: 2.5em;
    z-index: 1;
    transition: opacity ease-in-out 0.5s;
    font-weight: normal;
  }
  

  .content-container {
    width: 95%;
    margin-left: 5%;
    position: relative;
  }
  
  .transition-overlay{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    background-color: white;
    opacity: 0;
    z-index: 2;
    transition: opacity ease-in-out 0.5s;
  }

  .transition-overlay.show{
    opacity: 1;
  }
  

  .jewelry-header-text.hide{
    opacity: 0;

  }

  @media only screen and (max-width: 900px) {

    .jewelry-container{
      width: 100%;
      padding-bottom: 0vh;
      margin-top: 10vh;
    }

    .jewelry-content-container{
      flex-direction: column;
      margin-left: 5%;
      margin-top: 5vh;
      margin-bottom: 5vh;
    }
    .jewelry-header-text{
      position: relative;
      top: 0;
      margin-top: 5%;
      margin-bottom: 5%;
      align-self: center;
    }
    .content-container{
      margin: 0;
      margin-top: 10vh;
      display: flex;
      flex-direction: column;
    }

  
  }
/* Overlay Styles */
.overlay {
  position: fixed; /* Full screen overlay */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black; /* Black background */
  z-index: 3; /* Ensure it stays on top of everything */
  opacity: 0; /* Start hidden */
  pointer-events: none; /* Disable interactions */
}

.overlay-show {
  opacity: 1;
  transition: opacity 0.2s ease-in; /* Faster fade-in */
}

.overlay-hide {
  opacity: 0;
  transition: opacity 0.5s ease-out; /* Slower fade-out */
}

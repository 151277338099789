.footer {
  background-color: rgb(0, 0, 0);
  margin-left: 15%;
  margin-right: 20%;
  text-align: left;
  position: relative;
  font-family: 'Raleway', sans-serif;
  /* Add Raleway font */
  display: flex;
  padding-top: 5vh;

}

.footer-empty {
  width: 10%;
  background-color: #3A3A3A;
}

.footer-wrapper {
  width: 90%;
}

.footer-top {
  position: relative;
  width: 110%;
  height: 4vh;
  background-color: #3A3A3A;

}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #3A3A3A;
  font-size: 0.8em;
  width: 100%;
  padding-right: 10%;
  padding-bottom: 1em;
}

.contact-info,
.address,
.social-links {
  margin-bottom: 1em;
  margin-top: 2em;
}

.info-box {
  display: flex;
  align-items: flex-start;
}


.email-title,
.address-title,
.kontakt-title {
  margin-right: 1em;
  /* Space between the title and content */
  color: rgb(132, 132, 125);
}

.kontakt-title {
  text-decoration: underline;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  margin-left: 1em;
}


.content p {
  margin-top: 0.1em;
  margin-bottom: 0.1em;
  color: #FFFFFF;
  font-weight: lighter;
}

.social-links .content {
  display: flex;
  flex-direction: row;
  /* Change to row for horizontal alignment of icons */
  align-items: center;
  /* Align the icons to the center of the container */
}

.social-links .content a {
  font-size: 1.5rem;
  margin-right: 10px;
  color: #000;
}

.footer-bottom {
  display: flex;
  border-top: 1px solid #6a6a6a;
  padding-top: 1em;
  background-color: #3A3A3A;
  align-items: center;
  font-size: 0.8em;
  color: rgba(120, 120, 109, 255);
  width: 110%;
}

.footer-bottom p {
  color: rgb(132, 132, 125);
}

.footer-bottom a {
  margin-left: 1em;
}


.footer-bottom a:hover {
  text-decoration: underline;
}

/* Default link style */
.footer a {
  color: inherit;
  /* Inherit the color from the parent element */
  text-decoration: none;
  /* Remove underline */
}


/* Link active state (when clicked) */
.footer a:active {
  color: inherit;
  /* No color change when the link is clicked */
}

/* Make the icons white */
.content a i {
  color: white;
  /* Change the icon color to white */
}

/* Optional: Add hover effect */
.content a:hover i {
  color: #ccc;
  /* Change the color on hover (optional) */
}

#linkfooter{
  text-decoration: underline;
  text-decoration-color: #FFFFFF;
}


/* Responsive Styles for Mobile (max-width: 900px) */
@media screen and (max-width: 1350px) {
  .footer {
    width: 100%;
    margin: 0;
  }

  .footer-empty {
    display: none;
  }

  .footer-top {
    width: 100%;
  }

  .footer-wrapper{
    width: 100%;
  }

  .footer-content {
    flex-direction: column;
    /* Stack items vertically */
    justify-content: center;
    text-align: center;
    /* Center text for mobile */
    padding: 0;
    width: 100%;
  }


  .footer-bottom{
    width: 100%;
  }

  .contact-info,
  .address,
  .social-links {
    margin-bottom: 1.5em;
    margin-top: 1.5em;
    width: 100%;
    /* Make each section take full width */
  }

  .info-box {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* Stack title and content vertically */
    text-align: center;
  }

  .email-title,
  .address-title,
  .kontakt-title {
    margin-right: 0;
    /* Remove right margin for titles on mobile */
    margin-bottom: 0.5em;
    /* Add spacing between title and content */
  }

  .content {
    align-items: center;
    /* Center-align content */
  }

  .footer-bottom {
    flex-direction: column;
    /* Stack copyright and links vertically */
    text-align: center;
    padding-bottom: 1em;
  }

  .footer-bottom p {
    margin-left: 0;
    margin-bottom: 1em;
    /* Add margin between elements */
  }

  /* Make the icons white */
.content a i {
margin-top: 1vh;
}

.social-links .content{
  margin: 0;
}

.social-links .content a:nth-of-type(2) {
  margin: 0;


}

.footer-bottom-div{
width: 100%;
}

.footer-bottom-div a{
  margin: 5%;
  }
}

.trend-detail {
  background-color: black;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10vh;
}

.trend-detail-container {
  position: relative;
  background-color: rgb(255, 255, 255);
  z-index: 2;
  width: 95%;
  height: 100%;
  overflow: auto;
  padding-bottom: 10%;
  transform: translateX(-100vw); /* Start off-screen to the left */
  transition: transform 0.7s ease-in-out; /* Transition effect */
}

/* Fly-in effect to move the container into the view */
.trend-detail-container.fly-in {
  transform: translateX(0); /* Bring the container into view */
}

.trend-detail-container.fly-out {
  transform: translateX(-100vw); /* Move container off-screen to the left */
}

.trend-detail-content {
  margin-top: 10vh;
  display: flex;
  margin-left: 20%;
  margin-right: 10%;
  flex-direction: column;
}

.trend-detail-content p {
  max-width: 100%;
}

.trend-detail-content img {
  cursor: pointer;
}

.detail-header {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: start;
}

.detail-header p {
  font-family: 'Raleway', sans-serif;
  color: rgb(169, 155, 112);
  margin: 0;
  height: 2vh;
}

.detail-header h1 {
  font-family: 'Times New Roman';
  align-self: start;
  max-width: 70%;
  font-weight: normal;
  height: 10vh;
}

.detail-image-collection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
  margin-top: 2em;
  width: 100%;
}

.detail-image-collection img {
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  cursor: pointer;
}

.modal-content img {
  width: 90vw;
  cursor: pointer;
}


@media (max-width: 900px) {

  .detail-header{
    justify-content: center;
    align-items: center;
  }

  .detail-header p{
    height: auto;

 }

  .detail-header h1{
     font-size: 2rem;
     max-width: 100%;
     margin-top: 5%;
     height: auto;
  }
  
  .detail-header img{
    max-width: 100%;
    margin-top: 5%;
  }

  .detail-image-collection{
    margin-top: 5%;
  }

  .trend-detail-content{
    margin: 5%;
    margin-top: 10vh;
  }

  .trend-detail-content p{
    text-align: left;
  }

  .trend-detail-container {

    width: 100%;
  }
}
.product-page {
    overflow: visible;
    display: flex;
    align-items: flex-start; /* Align content to the top */
    justify-content: center;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: all;
    transition: opacity ease-in-out 0.3s;
    font-family: 'Raleway', sans-serif; /* Add Raleway font */
    padding: 0;

}
.product-page h1{
    align-self: flex-start;
    font-weight: normal;
    margin-bottom: 0.4em;
    font-family: 'Times New Roman';
    font-size: 2.5em;
}

.product-page.hidden {
    opacity: 0;
    pointer-events: none;
}

.product-img {
    max-width: 50%;
    margin-top: -10vh;
}

.description-box {
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start; /* Ensure flex items are at the top */
    height: 100%; /* Make sure the box takes the full height */
    margin-right: 15%;
}

.description-box h1{
    color: #A99F92;
    margin-top: 0;
}

.description-box p{
    line-height: 24px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    cursor: pointer;
  }
  
  .modal-content {
    position: relative;
  }
  
  .modal-img {
    height: 100vh;
  }
  


@media only screen and (max-width: 1200px) {

    .product-page{

        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 5%;

    }

    .description-box{
        order: 2;
        margin: 0;
        align-items: center;
        text-align: center;
    }

    .description-box h1{
        align-self: center;
        margin-bottom: 0;
        margin-top: 5vh;
    }

    .product-img{
        order: 1;
        max-width: 100%;
        margin-top: 0;
    }


  
  }

  @media only screen and (max-width: 900px) {

    .product-page{
        padding-right: 0;
    }
  }
/* Logo.css */
.logo-container {
  position: fixed;
  top: 3%;
  left: 12%;
  z-index: 4;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container.hidden {
opacity: 0;
pointer-events: none;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 6.2em;
  height: auto;
  transition: opacity 0.5s ease; /* Smooth transition between logos */
  opacity: 0;
  z-index: 1; /* Ensure both images stack correctly */
  transform: translateX(-50%);
}

.logo.visible {
  opacity: 1; /* Make visible logo fully opaque */
  z-index: 2; /* Bring visible logo to front */
}

.logo.hidden {
  opacity: 0; /* Hide the non-active logo */
  z-index: 1; /* Ensure hidden logo is behind */
}


@media only screen and (max-width: 900px) {
  .logo {
    width: 6em;
  }


  .logo-container{
    top: 0;
  }




  .logo-container img{
    top: 2vh;

  }
}

@media only screen and (max-width: 500px) {
  .logo {
    width: 4em;
  }

}

@media only screen and (max-height: 500px) {
  .logo {
    width: 4em;
  }

}
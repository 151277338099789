.contact-page {
    position: relative;
    width: 95%;
    background-color: white;
    overflow: auto;
    padding-bottom: 10vh;
}

.contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20%;
    margin-right: 10%;
    margin-top: 20vh;
}

.contact-picture {
    width: 100%;
    height: auto;
}

.contact-header {
    align-self: flex-start;
    margin-top: 5vh;
    width: 100%;
    padding-bottom: 10vh;
    border-bottom: 1px solid #626262;
}

.contact-header h2 {
    margin: 0;
    font-size: 1.3em;
    font-family: 'Times New Roman';
    font-weight: normal;
    color: #A79688;
}

.contact-text {
    margin-top: 8vh;
    width: 100%;
}

.contact-text p {
    max-width: 50%;
    color: black;
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.3em;
}

.contact-sub-text {
    width: 100%;
    padding-bottom: 7vh;
}

.contact-sub-text p {
    max-width: 60%;
    font-size: 0.8em;
    font-family: Raleway;
}

.contact-form{
    align-self: flex-start;
    color: #8f7f7f;
}

.contact-form a{
    color: #8f7f7f;
}

.contact-form form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1em;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    font-size: 14px;
    margin-bottom: 0.5em;
    margin-top: 5vh;
    font-family: Raleway;
    color: #86868C;
}

.form-group input, .form-group textarea {
    border: none;
    border-bottom: 1px solid #ccc;

    font-size: 1em;
    outline: none;
 
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"] {
padding-bottom: 0;
margin-bottom: 0;
}

.form-group textarea {
    height: 100%;
    resize: none;
}

.form-group.right {
    height: 100%;
    resize: none;
}

.form-group.privacy {
    margin-top: 5%;
    align-items: flex-start;
    width: 100%;
    flex-direction: row;
}

.form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.left-column {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.right-column {
    flex: 0 0 45%;
}

.privacy-consent {
    display: flex;
    width: 100%;
    align-self: flex-start;
    flex-direction: row;
}

.privacy-consent input {
    max-width: 1em;   
    margin-right: 1em;

}

.privacy-consent span {
    margin: 0;
}

button[type="submit"] {
    color: rgb(181, 167, 104);
    border: 1px solid rgb(181, 167, 104);
    padding: 5px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    align-self: flex-end;
    background-color: transparent;
}

button[type="submit"]:hover {
background-color: rgb(237, 227, 227);
}

.form-status {
    margin-top: 15px;
    font-size: 14px;
    color: #d9534f; /* Error color */
}



@media only screen and (max-width: 900px) {


    .contact-page{
        width: 100%;
    }

    .contact-content{
        margin: 0;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 15vh;
        overflow-x: none;
    }

    .contact-text p {
        max-width: 100%;
    }

    .contact-sub-text p{
        max-width: 100%;
    }

}
.data-privacy-page {
    width: 100%;
    font-family: 'Raleway', sans-serif; /* Add Raleway font */
    overflow: auto;
    background-color: white;
}

.data-privacy-page a {

    color: black;
}

.privacy-content {
    margin-top: 20vh;
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    padding-bottom: 10%;
}


.header-wrapper-privacy {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid rgb(210, 200, 200);
    margin-bottom: 8vh;
}

.header-wrapper-privacy h1 {
    align-self: center;
    font-size: 3rem;
    margin-bottom: 10vh;
    font-weight: normal;
    font-family: 'Times New Roman', Times, serif;
    color: #A79688;

}

.privacy-section.impressum2 p {
    margin: 0;

}

.privacy-section.impressum2 h2 {

    font-family: 'Raleway', sans-serif; /* Add Raleway font */
}

.privacy-section h2 {
    font-size: 1.2em;
    color: #000000;
    margin-bottom: 0;
    font-weight: bold;
}

.privacy-section p {
    font-size: 1em;
    color: #000000;
    margin-top: 0;
}


.privacy-section.impressum {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10vh;
    border-bottom: 1px solid rgb(210, 200, 200);
    font-family: 'Times New Roman', Times, serif;
    margin-bottom: 8vh;
}

.privacy-section.impressum h2 {
    font-weight: normal;
    font-size: 1.5em;
    text-align: center;
    margin: 5vh;
}

.privacy-section.impressum p {
    font-size: 1.5em;
    text-align: center;
    margin: 0;
}

.privacy-section.impressum a+a {
    margin-bottom: 3vh;
}

.privacy-section a {
    font-size: 1em;
    text-decoration: none;
    margin: 0;
}

.privacy-section.impressum a {
    font-size: 1.5em;
    text-decoration: none;
    margin: 0;
}

#a1{
    margin-top: 3vh;
}


@media only screen and (max-width: 900px) {

    .header-wrapper-privacy h1 {
        max-width: 100%;
        font-size: 2em;
    }

    .header-wrapper-privacy {
        margin-bottom: 0;
    }


    .privacy-content {
        margin-top: 15vh;
        width: auto;
        margin-left: 5%;
        margin-right: 5%;

    }


}
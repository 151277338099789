/* Disable scrolling on the body when overlay is active */
.no-scroll {
  overflow: hidden;
}

.custom-picture {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.image-container {
  position: relative;
}

.thumbnail {
  width: 100%;
  height: auto;
  display: block;
}

/* Play Button Styles */
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 3px solid rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  pointer-events: none
}

.play-button::before {
  content: '';
  display: inline-block;
  border-left: 12px solid rgba(255, 255, 255, 0.8);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  margin-left: 4px;
}


.TrendDetail-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5em 3em 5em 3em; /* Increased padding for a larger box */
  background-color: rgb(242, 240, 234); /* Light yellow box color */
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out; /* Added transition for background-color */
  position: absolute;
  left: 0;
  top: 10vh;
  width: 5vw;
}

.TrendDetail-button-container.hovered {
  background-color: rgb(175, 151, 132); /* Light grey color on hover */
}

.TrendDetail-button {
  position: absolute;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}


.TrendDetail-button.hovered {
  transform: translateX(-100%);
  opacity: 0;
}


.TrendDetail-arrow {
  position: relative;
  width: 60px;
  height: 2px;
  background-color: #b49a8d; /* Arrow line color */
}

.TrendDetail-arrow-line {
  width: 100%;
  height: 2px;
  background-color: #b49a8d; /* Arrow line color */
}

.TrendDetail-arrow-head {
  position: absolute;
  top: -4px;
  left: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid #b49a8d; /* Arrow head color */
  transform: rotate(180deg);
}








.TrendDetail-button-white {
  position: absolute;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transform: translateX(100%);
  opacity: 0;
}


.TrendDetail-button-white.hovered {
  transform: translateX(0);
  opacity: 1;
}


.TrendDetail-arrow-white {
  position: relative;
  width: 60px;
  height: 2px;
  background-color: #ffffff; /* Arrow line color */
}

.TrendDetail-arrow-line-white {
  width: 100%;
  height: 2px;
  background-color: #ffffff; /* Arrow line color */
}

.TrendDetail-arrow-head-white {
  position: absolute;
  top: -4px;
  left: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid #ffffff; /* Arrow head color */
  transform: rotate(180deg);
}





.TrendDetail-overlay {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%; /* Adjust width to match arrowhead size */
  height: 50%;
  background-color: rgb(242, 240, 234); /* Same color as background */
  z-index: 1; /* Ensure it overlays the arrow */
  transition: background-color 0.2s ease-in-out;
}

.TrendDetail-overlay.hovered {
  background-color: rgb(175, 151, 132);  /* Grey color when hovering */
}







@media (max-width: 900px) {

  .TrendDetail-button-container{
    display: none;
  }

}
.menubar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  transition: background-color 0.3s ease-in-out;
  background-color: black;
  font-family: 'Raleway', sans-serif; /* Add Raleway font */
}

.menubar.transparent{
  background-color: transparent;
}

.menubar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 12vh;
  top: 0;
  z-index: 3;
  transition: transform 0.2s ease-in-out
}

.menubar-container.hidden {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.menubar-container.hovered {
  background-color: rgb(40, 40, 40);
  width: 90%;
}

.menubar-nav {
  display: flex;
  gap: 5em;
  text-align: center; 
}

.menubar-nav a {
  color: #7F7F7F;
  text-decoration: none;
  font-size: 0.9em;
  opacity: 1;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
}

.menubar-nav a:hover {
  color: white;
  opacity: 1;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  background-color: rgb(40, 40, 40);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
}

.dropdown-menu a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 8em;
  font-size: 1.3em;
  opacity: 1;
  transition: opacity 0.2s ease-out, color 0.2s ease-out;
  position: relative;
  height: 100%;
  width: 100%;
}

.dropdown-menu a:hover {
color: rgb(190, 181, 135);

}

.menubar-nav a.active {
  opacity: 1;
  color: white;
}

.menubar-nav a.active_j {
  opacity: 1;
  color: rgb(0, 0, 0);
}


.dropdown-menu a::before {
  content: '';
  position: absolute;
  top: 99%;
  left: 50%;
  width: 0;
  height: 2px;
  background-color:  rgb(190, 181, 135);
  transition: width 0.4s ease, left 0.4s ease;
}

.dropdown-menu a:hover::before {
  width: 100%;
  /* Full width when hovered */
  left: 0;
  /* Move the starting point to the left */
  justify-content: center;
}

/* Border between menu items */
.dropdown-menu a:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  /* Position the line to the right side */
  top: 0;
  /* Align to the top of the element */
  height: 100%;
  /* Make the line as tall as the entire element */
  border-right: 1px solid rgb(65, 65, 65);
  /* Grey line */
}


/* Move the index number to the top-left */
.dropdown-menu a span.dropdown-index {
  position: absolute;
  top: 10%;
  /* Adjust top position to fit your design */
  left: 5%;
  /* Adjust left position to fit your design */
  font-size: 0.6em;
  /* Make the number smaller */
  color: #ccc;
  /* Lighter color for the index number */
}


@media only screen and (max-width: 900px) {
  .menubar {
    display: none;
    pointer-events: none;
  }
}
/* CategorySelector.css */
.category-selector {
    font-family: Arial, sans-serif;
    text-align: left;
    position: relative;
    top: 0;
    font-family: 'Raleway', sans-serif; /* Add Raleway font */
  }
  
  .category-selector h2 {
    font-size: 1.2em;
    margin-bottom: 2em;
    font-weight: bold;
    margin-top: 0;
  }
  
  .category-selector .sub-category {
    font-size: 1em;
    margin: 0 0 0.5em 20%;
    cursor: pointer;
    color: #666;
    position: relative;
    padding-left: 10px; /* Add padding for space between text and the line */
  }
  
  .category-selector .sub-category.active {
    font-weight: bold;
    color: #000;
  }
  
  .category-selector .sub-category.active::before {
    content: '—';
    position: absolute;
    left: -20px; /* Adjust left for line position */
    top: 40%;
    transform: translateY(-50%); /* Center the line vertically */
    color: #000;
    font-weight: bold;
    font-size: 18px;
  }
  

  @media only screen and (max-width: 1000px) {

    .category-selector{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  
  }
.shadow{
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0;
    z-index: 2;
    pointer-events: none;
    transition: opacity ease-in-out 0.5s;
  }

  .shadow.show{
    opacity: 0.4;
  }
.home{
  background-color: black;
}

.main-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: black;
  font-family: 'Times New Roman';
}

.background-container {
  position: absolute;
  transition: transform ease-in-out 0.0s;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.mute-button {
  position: fixed;
  bottom: 5vh;
  right: 5vw;
  background-color: rgba(0, 0, 0, 0.0);
  color: white;
  opacity: 0.5;
  cursor: pointer;
  font-size: 1em;
  z-index: 2;
}


.background-container-overlay {
  position: absolute;
  width: 100%;
  height: 10%;
  background-color: black;
  top: 95%;

}

.background-video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-mask {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 90%);
  pointer-events: none;
  /* Make sure this doesn't block video interactions */
}


.home-scrollable-content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 3;
}

.spaced-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
  margin-bottom: 5%;
  margin-top: 5%;
  width: 75vw;
  margin-left: 20vw;
  margin-right: 5vw;
  position: relative;
}


#section-1 {
  margin-top: 0;
}

#section-0{
  margin-top: 30vh;
  margin-bottom: 70vh;
  align-self: flex-start;
}

.spaced-text a {
  text-decoration: none;
  /* Remove underline from links */
  display: inline-block;
  /* Ensure proper alignment */
  position: relative;
  /* Prevent overflow */
  color: #FFE6CB;
  
}


.extra-wrapper {
  overflow: hidden;
  /* Hide the overflow */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  
}

.image-wrapper {
  overflow: hidden;
  
  max-width: 100%;

}

.spaced-text img,
.spaced-text video {
  width: 100%;
  /* Adjust the size of the image or video */
  height: auto;
  position: relative;
  transition: transform 0.3s ease;
  /* Smooth zoom-in transition */
  object-fit: cover;
  /* Ensure proper scaling of the content */
}


.spaced-text a img:hover,
.spaced-text img:hover {
  transform: scale3d(1.1, 1.1, 1);
  /* Zoom in the image */
  transform-origin: center;
  /* Zoom from the center */
}

.title-text {
  color: #FFE6CB;
  font-size: 2.2em;
  position: absolute;
  z-index: 3;
  left: 5%;
}


.spaced-text h2{
  font-weight: normal;
  font-size: 1em;
  margin: 0;
}

.title-text p{
margin: 0;
font-size: 0.6em;
color: white;
}

.spaced-text h1{
  font-weight: normal;
  font-size: 1em;
}


.video-content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.content-video-home {
  width: 100%;
  height: auto;
  max-height: 100%;
  /* Adjust this value based on your design */
}


/* Scroll Indicator*/


.scroll-indicator {
  position: fixed;
  top: 50%;
  left: 12%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 5vh;
  z-index: 1;

}

.scroll-indicator::before,
.scroll-indicator::after {
  content: '';
  width: 1px;
  opacity: 0.5;
  height: 12vh;
  /* Adjust the height to match the new spacing */
  background-color: #ccc;
  /* Color of the lines */
}

/* Style the dots */
.dot {
  position: relative;
  width: 2px;
  height: 2px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
  /* Change cursor to pointer to indicate clickability */
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

/* Make the hitbox bigger using a ::before pseudo-element */
.dot::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2em;
  /* Increase the size of the clickable area */
  height: 2em;
  /* Increase the size of the clickable area */
  border-radius: 50%;
  background-color: transparent;
  /* Invisible, just acts as a clickable area */
}

/* Add the hover effect */
.dot:hover {
  background-color: #fff;
  /* Light up the dot when hovered */
  transform: scale(1.4);
  /* Slightly enlarge the dot on hover */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  /* Add a soft glow effect */
}

/* Active dot style */
.dot.active {
  background-color: #ccc;
  width: 4px;
  height: 4px;
}

/* The active ring effect */
.dot.active::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0.6;
  animation: ringEffect 0.3s ease-out forwards;
}

/* Keyframe for the active ring */
@keyframes ringEffect {
  from {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }

  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}




@media only screen and (max-height: 500px) {
  .scroll-indicator {
    display: none;
  }
}


@media only screen and (max-width: 900px) {
  .scroll-indicator {
    display: none;
  }

}


@media only screen and (max-width: 900px) {



  .video-container{
    height: 100%;
  }



  .home-scrollable-content {
    padding: 0;
  }

  .spaced-text {
    position: relative;
    margin: 10%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .title-text {
    position: absolute;
    top: 5%;
    left: 5%;
    font-size: 3rem;
    z-index: 4;
  }

  .image-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  .image-container {
    width: 100%;
    /* Make image fill the screen width */
    height: auto;
  }

  .extra-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }


  /* Adjust the size of the downward arrow */
  .downward-arrow {
    width: 30px;
    height: 30px;
  }



}


@media only screen and (max-width: 600px) {

  

  .title-text {

    font-size: 1.5rem;
    pointer-events: none;

  }



}
@media only screen and (max-width: 900px) {
    .menubar-small {
        position: fixed;
        width: 100%;
        height: 10vh;
        background-color: #212121;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: row;
                z-index: 3;
      }
    
    .menubar-small.transparent {
        background-color: transparent;
    }

    .menubar-small-container {
        position: absolute;
        background-color: transparent;
        height: 100%;
        transition: transform 0.3s ease-in-out;
        width: 20%;
        right: 0;
        z-index: 4;
    }
    

    .menubar-small-container.hidden {
        transform: translateY(-100%);
    
    }
    
    .hamburger-icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        top: 2.7vh;
        left: 20px;

        
    }
    
    .hamburger-icon .line {
        width: 100%;
        height: 1px;
        background-color: #5b5b5b;
        transition: all 0.3s ease;
    }
    

    .dropdown-menu-small{
        position: absolute;
        top: -100vh;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: black;
        transition: transform 0.3s ease-in-out;
        z-index: 3;

    }

    .dropdown-menu-small.show{
        transform: translateY(100%);
    }

    .navigator-small{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    .navigator-small a{
        color: #D9B69A;
        text-decoration: none;
        font-size: 3rem;
        margin: 0.5em;
        font-family: 'Times New Roman';
    }

    
}


@media only screen and (min-width: 901px) {

.menubar-small{
    display: none;
}

}


@media only screen and (max-height: 500px) {

.menubar-small{
    height: 15vh;
}

}


@media only screen and (max-width: 500px) {


    
    .navigator-small a{
        font-size: 1.7rem;
    }

}
.video-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 7;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
}

.youtube-video {
    width: 80%;
    height: 80%;
    max-width: 1280px;
    max-height: 720px;
    border: none;
  }